import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Cookies from "universal-cookie";

class TopMenu extends Component {
  state = {
    domainName: window.location.hostname,
  };

  handleLogout() {
    const cookies = new Cookies();
    cookies.remove("user_id");
    cookies.remove("token");
    window.location = "/";
  }

  render() {
    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Brand href="/dashboard">PIQ BUYBACK Admin</Navbar.Brand>
        <Nav className="mr-auto" activeKey={this.props.activeKey}>
          <Nav.Link href="/triggers">Triggers</Nav.Link>
          <Nav.Link href="/users">Users</Nav.Link>
          <Nav.Link href="/sounds">Sounds</Nav.Link>
          <Nav.Link href="/scans">Scans</Nav.Link>
          <Nav.Link href="/shipping-plans">Shipping Plans</Nav.Link>
        </Nav>

        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav activeKey={this.props.activeKey}>
            {"sources" in this.props && this.props.sources !== undefined && this.props.sources.length >= 1 && (
              <NavDropdown title="Sources" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => this.props.fetchStats("")}>ALL</NavDropdown.Item>
                <NavDropdown.Divider />
                {this.props.sources.map((source) => {
                  return (
                    <NavDropdown.Item onClick={() => this.props.fetchStats(source.source)} href="">
                      {source.source}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            )}
            <Nav.Item>
              <Nav.Link href="/account-settings">Account</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;
