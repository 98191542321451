import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Tabs, PanelList, Panel, TabList, Tab } from "react-tabtab";
import * as customStyleMD from "react-tabtab/lib/themes/material-design";
import AmazonCatTriggers from "./AmzonCatTriggers";

class AmzTriggers extends Component {
  state = {
    activeIndex: 0,
  };

  render() {
    let isMEnabled = false;

    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function(module) {
        return module.module_name === "Amazon";
      });
      isMEnabled = config_high_level[index].enabled;
    }
    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row>
              <Col md={12}>
                <Tabs customStyle={customStyleMD} showModalButton={false}>
                  <TabList>
                    <Tab>
                      <i className="fa fa-book" />
                      &nbsp; Books
                    </Tab>
                    <Tab>
                      <i className="fa fa-music" />
                      &nbsp; Music
                    </Tab>
                    <Tab>
                      <i className="fa fa-video-camera" />
                      &nbsp; Video
                    </Tab>
                    <Tab>
                      <i className="fa fa-gamepad" />
                      &nbsp; Video Games
                    </Tab>
                  </TabList>
                  <PanelList>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_books"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_music"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_video"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                    <Panel>
                      <AmazonCatTriggers
                        batchConfiguration={this.props.batchConfiguration}
                        triggersType={"conf_amazon_video_games"}
                        updateTBConfig={this.props.updateTBConfig}
                        admin_data={this.props.admin_data}
                        triggerBatchId={this.props.triggerBatchId}
                      />
                    </Panel>
                  </PanelList>
                </Tabs>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default AmzTriggers;
