import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Table, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { getTriggerBatches, addTriggerBatch, delTriggerBatch, duplicateTriggerBatch } from "../includes/HelperTriggers";
import { loadTeams } from "../includes/HelperUsers";
import ModalAddTriggerBatch from "../components/Others/ModalAddTriggerBatch";
import ModalDuplicateTriggerBatch from "../components/Others/ModalDuplicateTriggerBatch";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class Triggers extends Component {
  state = {
    trigger_batches: [],
    isLoadingBatches: false,
    showAddModal: false,
    showDuplicateModal: false,
    tbClipboard: {},
    isDeleting: false,
    isDuplicating: false,
    admin_data: cookies.get("admin_data"),
    teams: [],
  };

  handleLogout() {
    cookies.remove("admin_data");
    window.location = "/?token-expiry=1";
  }

  constructor() {
    super();
    this.loadTriggerBatches = this.loadTriggerBatches.bind(this);
    this.onFormSubmitAddTriggerBatch = this.onFormSubmitAddTriggerBatch.bind(this);
    this.onFormSubmitDuplicateTriggerBatch = this.onFormSubmitDuplicateTriggerBatch.bind(this);
  }

  componentDidMount() {
    this.loadTriggerBatches();
    this.loadTeams();
  }

  async loadTeams() {
    if (this.state.admin_data.access_level === 0) {
      const teams = await loadTeams(this.state.admin_data);
      teams !== false && this.setState({ teams: teams.data });
    } else {
      console.log("NOT SUPER");
    }
  }

  loadTriggerBatches = async () => {
    this.setState({ isLoadingBatches: true });
    const response = await getTriggerBatches(this.state.admin_data);
    if ("error" in response && response.error === "Invalid Credentials") this.handleLogout();
    this.setState({ trigger_batches: response, isLoadingBatches: false });
  };

  handleEditTriggerBatch(trigger_batch, _index) {
    window.location = "/edit-trigger-batch/" + trigger_batch.id + "/" + trigger_batch.name;
  }

  async deleteTriggerBatch(trigger_batch, _index) {
    console.log(trigger_batch);
    this.setState({ isLoadingBatches: true });
    const response = await delTriggerBatch(this.state.admin_data, trigger_batch.id);
    console.log(response);
    if (response.status === "success") this.setState({ trigger_batches: response.data, isLoadingBatches: false });
  }

  handleDeleteTriggerBatch(trigger_batch, _index) {
    confirmAlert({
      title: "Delete Trigger Batch?",
      message: "This will permanently delete this trigger batch. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteTriggerBatch(trigger_batch, _index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async onFormSubmitAddTriggerBatch(event) {
    event.preventDefault();

    const triggerBatchName = event.target.elements.triggerName.value;
    const teamId = "teamId" in event.target.elements ? event.target.elements.teamId.value : 0;

    // Call API to add trigger
    const response = await addTriggerBatch(this.state.admin_data, triggerBatchName, teamId);
    response !== false && response.status === "success" && this.setState({ trigger_batches: response.data });
    this.setState({ showAddModal: false });
  }

  async onFormSubmitDuplicateTriggerBatch(event) {
    event.preventDefault();
    const triggerBatchName = event.target.elements.triggerName.value;
    const teamId = "teamId" in event.target.elements ? event.target.elements.teamId.value : 0;
    this.setState({ isDuplicating: true });

    // Call API to add trigger
    const response = await duplicateTriggerBatch(
      this.state.admin_data,
      triggerBatchName,
      this.state.tbClipboard.id,
      teamId
    );
    response !== false && response.status === "success" && this.setState({ trigger_batches: response.data });
    this.setState({ showDuplicateModal: false, isDuplicating: false });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu activeKey="/triggers" />

        <ModalAddTriggerBatch
          teams={this.state.teams}
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddTrigger={this.onFormSubmitAddTriggerBatch}
        />

        <ModalDuplicateTriggerBatch
          teams={this.state.teams}
          tbClipboard={this.state.tbClipboard}
          showAddModal={this.state.showDuplicateModal}
          isDuplicating={this.state.isDuplicating}
          handleClose={() => this.setState({ showDuplicateModal: false })}
          onFormSubmitAddTrigger={this.onFormSubmitDuplicateTriggerBatch}
        />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={8}>
              <div>
                <h3>Trigger Batches</h3>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ textAlign: "right" }}>
                {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: "8px" }}
                    variant="success"
                    onClick={() => this.setState({ showAddModal: true })}
                  >
                    Add New Batch
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Trigger ID</th>
                    <th>Trigger Name</th>
                    {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                      <th>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.isLoadingBatches === false ? (
                    this.state.trigger_batches.map((trigger_batch, _index) => {
                      return (
                        <tr key={trigger_batch.id}>
                          <td>{trigger_batch.id}</td>
                          <td>{trigger_batch.name}</td>
                          {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                            <td>
                              <Button
                                className="actionButtonLessPadding"
                                variant="light"
                                onClick={() => this.handleEditTriggerBatch(trigger_batch, _index)}
                              >
                                Edit
                              </Button>
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="primary"
                                onClick={() => this.setState({ showDuplicateModal: true, tbClipboard: trigger_batch })}
                              >
                                Duplicate
                              </Button>
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="danger"
                                onClick={() => this.handleDeleteTriggerBatch(trigger_batch, _index)}
                              >
                                Delete
                              </Button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Triggers;
