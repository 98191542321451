import React, { Component } from "react";
import {
  loadTriggers,
  booksCategoryList,
  updateTrigger,
  addTrigger,
  deleteTrigger,
} from "../../../includes/HelperTriggers";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { reconfigTB } from "../../../includes/HelperTriggerBatches";

import TableListTriggers from "../Modals/TriggersHitList/TableListTriggers";
import ModalEditTrigger from "../Modals/TriggersHitList/ModalEditTrigger";
import ModalAddTrigger from "../Modals/TriggersHitList/ModalAddTrigger";

class HitListTriggers extends Component {
  state = {
    triggers: [],
    triggerRows: [],
    showEditModal: false,
    showAddModal: false,
    editTrigger: {},
    categoryList: [],
    triggerType: 4,
    triggerBatchId: -1,
    isLoading: false,
    isTriggerEnabled: false,
    savingTriggerDifference: false,
    config_hitlist: {},
    targetDiff: 0,
  };

  constructor() {
    super();
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
    this.onClickDeleteTrigger = this.onClickDeleteTrigger.bind(this);
    this.initiateLoadTriggers = this.initiateLoadTriggers.bind(this);
    this.handleTriggerToggle = this.handleTriggerToggle.bind(this);
    this.handleReConfig = this.handleReConfig.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId }, this.initiateLoadTriggers);

    if ("conf_hitlist" in this.props.batchConfiguration && this.props.batchConfiguration.conf_hitlist !== null) {
      const config_hitlist = JSON.parse(this.props.batchConfiguration.conf_hitlist);
      this.setState({ config_hitlist });
      if ("enabled" in config_hitlist) {
        this.setState({
          isTriggerEnabled: config_hitlist.enabled,
          targetDiff: config_hitlist.target_diff,
        });
      }
    }
  }

  initiateLoadTriggers() {
    this.fetchTriggers();
    this.setState({ categoryList: booksCategoryList });
  }

  /**
   * Reads Triggers from Datbase using our API and saves it to state.
   * Should we use Redux for this??
   */
  async fetchTriggers() {
    this.setState({ isLoading: true });
    const triggersList = await loadTriggers(this.props.admin_data, this.state.triggerBatchId, this.state.triggerType);
    this.setState({ triggers: triggersList, isLoading: false });
  }

  handleEditShow = (trigger, _index) => {
    let triggerClone = trigger;
    triggerClone.jsonData = JSON.parse(trigger.TriggerJson);
    triggerClone.categories = booksCategoryList;

    this.setState({ showEditModal: true, editTrigger: triggerClone });
  };

  /**
   * Gets called when user submits 'Edit Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitEditTrigger(triggerName, hitListTarget, isbnList, isAlwaysReject = false) {
    // Gather required fields
    const triggerId = this.state.editTrigger.Id;

    let triggerJson = { target_price: hitListTarget, asins: isbnList, isAlwaysReject: isAlwaysReject };

    // Call API to update trigger
    const response = await updateTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      triggerName,
      triggerJson,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ showEditModal: false });
  }

  /**
   * Gets called when user submits 'Add Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitAddTrigger(triggerName, hitListTarget, isbnList, isAlwaysReject = false) {
    let triggerJson = { target_price: hitListTarget, asins: isbnList, isAlwaysReject: isAlwaysReject };

    // Call API to add trigger
    const response = await addTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerName,
      triggerJson,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ showAddModal: false });
  }

  async onClickDeleteTrigger(trigger, _index) {
    // Gather required fields
    const triggerId = trigger.Id;
    console.log(triggerId);
    // Call API to delete trigger
    const response = await deleteTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      this.state.triggerType
    );
    response !== false && response.status === "success" && this.setState({ triggers: response.data });
    this.setState({ showAddModal: false });
  }

  handleTriggerToggle(evt) {
    this.setState({ isTriggerEnabled: evt.target.checked });
  }
  saveTargetDifference(evt) {
    console.log("Hi");
  }

  async handleReConfig() {
    let newConfig = {};
    const targetDiff = this.state.targetDiff;
    if (targetDiff >= 0) {
      newConfig.enabled = true;
      newConfig.target_diff = targetDiff;
      this.setState({ isTriggerEnabled: true });
    } else {
      newConfig.enabled = false;
      this.setState({ isTriggerEnabled: false });
    }

    await reconfigTB(this.props.admin_data, this.props.triggerBatchId, "conf_hitlist", JSON.stringify(newConfig));
  }

  render() {
    let isMEnabled = false;
    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "HitList";
      });
      isMEnabled = config_high_level[index].enabled;
    }

    return (
      <React.Fragment>
        <ModalEditTrigger
          showEditModal={this.state.showEditModal}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditTrigger={this.onFormSubmitEditTrigger}
          editTrigger={this.state.editTrigger}
        />

        <ModalAddTrigger
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddTrigger={this.onFormSubmitAddTrigger}
        />

        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row>
              <Col md={3}></Col>

              {this.state.isTriggerEnabled === true && (
                <Col md={9}>
                  <div style={{ textAlign: "right", marginTop: "5px" }}>
                    <Button
                      className="actionButtonLessPadding"
                      style={{ marginLeft: "8px" }}
                      variant="success"
                      onClick={() => this.setState({ showAddModal: true })}
                    >
                      Add New HitList Trigger
                    </Button>
                  </div>
                </Col>
              )}
            </Row>

            {this.state.isTriggerEnabled === true && (
              <Row style={{ marginTop: "20px" }}>
                <Col md={12}>
                  {this.state.isLoading ? (
                    <div style={{ textAlign: "center", paddingTop: "100px" }}>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <TableListTriggers
                      triggers={this.state.triggers}
                      handleEditShow={this.handleEditShow}
                      deleteTrigger={this.onClickDeleteTrigger}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default HitListTriggers;
