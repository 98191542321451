import React, { Component } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";

class ModalEditTrigger extends Component {
  state = {
    currentCSVFile: null,
    taText: "",
    isAlwaysReject: false,
  };
  constructor(props) {
    super(props);
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
    this.onChangeCSVFile = this.onChangeCSVFile.bind(this);
    this.handleChangeTaText = this.handleChangeTaText.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  fetchHitList() {
    if ("jsonData" in this.props.editTrigger) {
      if ("isAlwaysReject" in this.props.editTrigger.jsonData)
        this.setState({
          isAlwaysReject: this.props.editTrigger.jsonData.isAlwaysReject,
        });
      else
        this.setState({
          isAlwaysReject: false,
        });

      const asins = this.props.editTrigger.jsonData.asins;
      let isbnTaText = "";
      for (let i = 0; i < asins.length; i++) {
        isbnTaText += asins[i] + "\n";
      }
      this.setState({ taText: isbnTaText });
    }
  }

  async onFormSubmitEditTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const hitListTarget = event.target.elements.hitListTarget.value;

    new Promise((resolve, reject) => {
      let asins = [];
      if (this.state.currentCSVFile !== null) {
        var reader = new FileReader();
        reader.onload = function (e) {
          const csvLines = e.target.result.split("\n");
          for (let i = 1; i < csvLines.length; i++) {
            const csvValues = csvLines[i].split(",");
            csvValues[0] !== "" && csvValues[0] !== undefined && asins.push(csvValues[0]);
          }
          resolve(asins);
        };
        reader.readAsText(this.state.currentCSVFile);
      } else resolve(asins);
    }).then((res) => {
      const arrISBNS = this.state.taText.split("\n");
      const arrISBNsFiltered = arrISBNS.filter((e1) => e1 !== "");
      const isbnList = res.concat(arrISBNsFiltered);
      this.props.onFormSubmitEditTrigger(triggerName, hitListTarget, isbnList, this.state.isAlwaysReject);
    });
  }

  onChangeCSVFile = (e) => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentCSVFile: e.target.files[0] });
    }
  };

  handleChangeTaText = (e) => {
    this.setState({ taText: e.target.value });
  };

  handleOpen() {
    this.fetchHitList();
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              defaultValue={this.props.editTrigger.TriggerName}
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <div>
                    <Form.Group controlId="formMinScore">
                      <Form.Label>Target Price</Form.Label>
                      <Form.Control
                        defaultValue={
                          "jsonData" in this.props.editTrigger ? this.props.editTrigger.jsonData.target_price : ""
                        }
                        required
                        type="number"
                        step="0.01"
                        name="hitListTarget"
                      />
                    </Form.Group>
                  </div>

                  <Row style={{ marginTop: "20px" }}>
                    <Col md={12}>
                      <div>
                        <Form.Group controlId="formAudioFile">
                          <Form.Label>Choose .csv File</Form.Label>
                          <Form.Control
                            onChange={this.onChangeCSVFile}
                            type="file"
                            name="csvFile"
                            placeholder="Choose .csv File"
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <div style={{ padding: "0 0 10px 0" }}>AND/OR</div>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Enter list of all ISBNs in new lines</Form.Label>
                          <Form.Control
                            value={this.state.taText}
                            as="textarea"
                            rows="8"
                            onChange={this.handleChangeTaText}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Check
                        onChange={(e) => this.setState({ isAlwaysReject: e.target.checked })}
                        checked={this.state.isAlwaysReject}
                        type="switch"
                        name="isAlwaysReject"
                        id="always-reject"
                        label="Always Reject?"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalEditTrigger;
