import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, FormControl, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getStats, getStatsCount, getStatsRejects, getSources } from "../includes/HelperDashboard";
import Cookies from "universal-cookie";
import { PieChart } from "react-chartkick";
import "chart.js";
import "../assets/style/theme_minimal.css";
import Select from "react-select";

const cookies = new Cookies();

const { SearchBar } = Search;

class Dashboard extends Component {
  state = {
    start_date: "",
    end_date: "",
    scan_data: [],
    sourcesData: [],
    selectedSources: [],
    filtered_scan_data: [],
    log_sources: [],
    isLoading: true,
    current_source: "",
    columns: [
      {
        dataField: "Name",
        text: "Name",
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: "",
      },
      {
        dataField: "Total Scans",
        text: "Total Scans",
        sort: true,
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "FBA",
        text: "FBA",
        sort: true,
        style: { background: "#00743F", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "FBA%",
        text: "FBA%",
        sort: true,
        style: { background: "#00743F", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.scan_data.length >= 1
            ? (this.state.scan_data.reduce((acc, item) => acc + item["FBA"], 0) /
                this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total FBA Profit",
        text: "Total FBA",
        sort: true,
        style: { background: "#00743F", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "Average FBA Profit",
        text: "Avg FBA",
        sort: true,
        style: { background: "#00743F", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total FBA Profit"], 0) /
              this.state.filtered_scan_data.reduce((acc, item) => acc + item["FBA"], 0)
            : 0
          ).toFixed(2),
      },
      {
        dataField: "MF",
        text: "MF",
        sort: true,
        style: { background: "#F4874B", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "MF%",
        text: "MF%",
        sort: true,
        style: { background: "#F4874B", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.scan_data.length >= 1
            ? (this.state.scan_data.reduce((acc, item) => acc + item["MF"], 0) /
                this.state.scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total MF Profit",
        text: "Total MF",
        sort: true,
        style: { background: "#F4874B", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "Average MF Profit",
        text: "Avg MF",
        sort: true,
        style: { background: "#F4874B", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total MF Profit"], 0) /
              this.state.filtered_scan_data.reduce((acc, item) => acc + item["MF"], 0)
            : 0
          ).toFixed(2),
      },
      {
        dataField: "RMS",
        text: "RMS",
        sort: true,
        style: { background: "#A7414A", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "RMS%",
        text: "RMS%",
        sort: true,
        style: { background: "#A7414A", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["RMS"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total RMS Profit",
        text: "Total RMS",
        sort: true,
        style: { background: "#A7414A", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "SBYB",
        text: "SBYB",
        sort: true,
        style: { background: "#6A8A82", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "SBYB%",
        text: "SBYB%",
        sort: true,
        style: { background: "#6A8A82", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SBYB"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total SBYB Profit",
        text: "Total SBYB",
        sort: true,
        style: { background: "#6A8A82", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "BS",
        text: "BS",
        sort: true,
        style: { background: "#A37C27", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "BS%",
        text: "BS%",
        sort: true,
        style: { background: "#A37C27", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SBYB"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total BS Profit",
        text: "Total BS",
        sort: true,
        style: { background: "#A37C27", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "SB",
        text: "SB",
        sort: true,
        style: { background: "#72A2C0", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "SB%",
        text: "SB%",
        sort: true,
        style: { background: "#72A2C0", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["SB"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total SB Profit",
        text: "Total SB",
        sort: true,
        style: { background: "#72A2C0", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "ZIFFIT",
        text: "ZIFFIT",
        sort: true,
        style: { background: "#0444BF", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "ZIFFIT%",
        text: "ZIFFIT%",
        sort: true,
        style: { background: "#0444BF", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["ZIFFIT"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total ZIFFIT Profit",
        text: "Total ZIFFIT",
        sort: true,
        style: { background: "#0444BF", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "BR",
        text: "BR",
        sort: true,
        style: { background: "#0D3C55", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "BR%",
        text: "BR%",
        sort: true,
        style: { background: "#0D3C55", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["BR"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total BR Profit",
        text: "Total BR",
        sort: true,
        style: { background: "#0D3C55", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "BRB",
        text: "BRB",
        sort: true,
        style: { background: "#1ABC9C", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "BRB%",
        text: "BRB%",
        sort: true,
        style: { background: "#1ABC9C", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? (this.state.filtered_scan_data.reduce((acc, item) => acc + item["BRB"], 0) /
                this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)) *
              100
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Total BRB Profit",
        text: "Total BRB",
        sort: true,
        style: { background: "#1ABC9C", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      },
      {
        dataField: "BookStore",
        text: "BookStore",
        sort: true,
        style: { background: "#040C0E", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "BookStore%",
        text: "BookStore%",
        sort: true,
        style: { background: "#040C0E", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["BookStore"], 0) /
              this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Hitlist",
        text: "Hitlist",
        sort: true,
        style: { background: "#6465A5", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "Hitlist%",
        text: "Hitlist%",
        sort: true,
        style: { background: "#6465A5", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Hitlist"], 0) /
              this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
            : 0
          ).toFixed(2) + "%",
      },
      {
        dataField: "Reject",
        text: "Reject",
        sort: true,
        style: { background: "#F20050", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      },
      {
        dataField: "Reject%",
        text: "Reject%",
        sort: true,
        style: { background: "#F20050", color: "#FFF" },
        headerStyle: { cursor: "pointer", outline: "none" },
        footer: (columnData) =>
          (this.state.filtered_scan_data.length >= 1
            ? this.state.filtered_scan_data.reduce((acc, item) => acc + item["Reject"], 0) /
              this.state.filtered_scan_data.reduce((acc, item) => acc + item["Total Scans"], 0)
            : 0
          ).toFixed(2) + "%",
      },
    ],
    scan_counts: { today: -1, week: -1, month: -1 },
    reject_counts: { today: -1, week: -1, month: -1 },
    isLoadingTableStats: false,
    admin_data: cookies.get("admin_data"),
  };

  handleLogout() {
    cookies.remove("admin_data");
    window.location = "/?token-expiry=1";
  }

  getFormattedNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getFormattedDate(dt) {
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;

    var yyyy = dt.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  constructor() {
    super();
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSourcesChange = this.handleSourcesChange.bind(this);
  }

  componentDidMount() {
    console.log(this.state.admin_data);
    if (this.state.admin_data.token === undefined || this.state.admin_data.admin_id === undefined) {
      cookies.remove("user_id");
      cookies.remove("token");
      window.location = "/";
    } else {
      const today = new Date();
      let date_today = this.getFormattedDate(today);
      this.setState({ start_date: date_today, end_date: date_today }, () => {
        this.fetchData();
      });
      //this.getSources();
    }
  }

  async fetchData() {
    this.setState({
      isLoadingTableStats: true,
      isLoading: true,
    });
    // Put them into single promise array and execute together.
    // 1. Fetch sources
    // 2. Fetch scan count
    // 3. Fetch scan rows
    let promises = [];

    promises.push(getSources(this.state.admin_data, this.state.start_date, this.state.end_date));
    promises.push(
      getStats(this.state.admin_data, this.state.start_date, this.state.end_date, this.state.current_source)
    );
    promises.push(getStatsCount(this.state.admin_data, this.state.current_source));
    promises.push(getStatsRejects(this.state.admin_data, this.state.current_source));

    // Combined response of all promises
    try {
      const promisesData = await Promise.all(promises.map((p) => p.catch((error) => null)));
      this.setState({ isLoading: false });

      if (promisesData === undefined || promisesData[0] === undefined) this.handleLogout();
      const sourcesData = promisesData[0].data.map((sd) => {
        return { value: sd.source, label: sd.source };
      });

      this.setState({
        sourcesData,
        scan_counts: promisesData[2],
        scan_data: promisesData[1],
        reject_counts: promisesData[3],
        isLoadingTableStats: false,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.handleLogout();
    }
  }

  async fetchStats() {
    let promises = [];
    this.setState({
      isLoadingTableStats: true,
      scan_counts: { today: -1, week: -1, month: -1 },
      reject_counts: { today: -1, week: -1, month: -1 },
      scan_data: [],
    });
    promises.push(
      getStats(this.state.admin_data, this.state.start_date, this.state.end_date, this.state.selectedSources)
    );
    promises.push(getStatsCount(this.state.admin_data, this.state.selectedSources));
    promises.push(getStatsRejects(this.state.admin_data, this.state.selectedSources));

    const promisesData = await Promise.all(promises.map((p) => p.catch((error) => null)));

    console.log(promisesData);

    this.setState({
      scan_data: promisesData[0],
      scan_counts: promisesData[1],
      reject_counts: promisesData[2],
      isLoadingTableStats: false,
    });
  }

  handleChangeStartDate(evt) {
    this.setState({ start_date: evt.target.value }, this.fetchStats);
  }
  handleChangeEndDate(evt) {
    this.setState({ end_date: evt.target.value }, this.fetchStats);
  }

  delay = async (duration) => {
    await setTimeout(console.log("Waiting for table update"), duration);
  };

  handleSourcesChange(selectedSources) {
    this.setState({ selectedSources }, this.fetchStats);
  }

  render() {
    const pie_chart_data = [
      ["FBA", this.state.scan_data.reduce((sum, sd) => sum + sd.FBA, 0), "HELLO"],
      ["MF", this.state.scan_data.reduce((sum, sd) => sum + sd.MF, 0)],
      ["SBYB", this.state.scan_data.reduce((sum, sd) => sum + sd.SBYB, 0)],
      ["BOOKSCOUTER", this.state.scan_data.reduce((sum, sd) => sum + sd.BS, 0)],
      ["SELLBOOKS", this.state.scan_data.reduce((sum, sd) => sum + sd.SB, 0)],
      ["BOOKSRUN", this.state.scan_data.reduce((sum, sd) => sum + sd.BR, 0)],
      ["BLUEROCKETBOOKS", this.state.scan_data.reduce((sum, sd) => sum + sd.SB, 0)],
      ["BOOKSTORE", this.state.scan_data.reduce((sum, sd) => sum + sd.SB, 0)],
      ["ZIFFIT", this.state.scan_data.reduce((sum, sd) => sum + sd.ZIFFIT, 0)],
      ["RMS", this.state.scan_data.reduce((sum, sd) => sum + sd.RMS, 0)],
      ["HITLIST", this.state.scan_data.reduce((sum, sd) => sum + sd.Hitlist, 0)],
      ["REJECT", this.state.scan_data.reduce((sum, sd) => sum + sd.Reject, 0)],
    ];
    const pieColors = [
      "#00743F",
      "#F4874B",
      "#6A8A82",
      "#A37C27",
      "#72A2C0",
      "#0D3C55",
      "#1ABC9C",
      "#040C0E",
      "#0444BF",
      "#A7414A",
      "#6465A5",
      "#F20050",
    ];

    return (
      <React.Fragment>
        <TopMenu fetchStats={this.fetchStats} sources={this.state.sources} activeKey="/" />
        <Container fluid={true} className="page-dashboard">
          <Row
            style={{
              padding: "20px",
              borderBottom: "1px solid #ddd",
              background: "#fdfdfd",
            }}
          >
            <Col md={4}>
              <PieChart height="250px" data={pie_chart_data} colors={pieColors} donut={true} />
            </Col>
            <Col
              md={8}
              style={{
                display: "grid",
                alignItems: "center",
              }}
            >
              <Row>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>TODAY</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.today >= 0
                        ? this.getFormattedNumber(this.state.scan_counts.today)
                        : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.today >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.today)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.today >= 0 && this.state.scan_counts.today >= 0
                        ? this.state.scan_counts.today > 0
                          ? parseInt((this.state.reject_counts.today / this.state.scan_counts.today) * 100) +
                            "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>THIS WEEK</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.week >= 0 ? this.getFormattedNumber(this.state.scan_counts.week) : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.week >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.week)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.week >= 0 && this.state.scan_counts.week >= 0
                        ? this.state.scan_counts.week > 0
                          ? parseInt((this.state.reject_counts.week / this.state.scan_counts.week) * 100) + "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="div-scan-count">
                    <h5>THIS MONTH</h5>
                    <div className="scan-count">
                      {this.state.scan_counts.month >= 0
                        ? this.getFormattedNumber(this.state.scan_counts.month)
                        : "..."}
                    </div>
                    <div
                      className="reject-count"
                      title={
                        this.state.reject_counts.month >= 0
                          ? this.getFormattedNumber(this.state.reject_counts.month)
                          : "..."
                      }
                    >
                      {this.state.reject_counts.month >= 0 && this.state.scan_counts.month >= 0
                        ? this.state.scan_counts.month > 0
                          ? parseInt((this.state.reject_counts.month / this.state.scan_counts.month) * 100) +
                            "% REJECTED"
                          : "0% REJECTED"
                        : "..."}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="tabletoolkit" md={12}>
              <div>
                <ToolkitProvider keyField="id" data={this.state.scan_data} columns={this.state.columns} search>
                  {(props) => (
                    <div>
                      <Row>
                        <Col md={7}>
                          <Row>
                            <Col md={4}>
                              <SearchBar className="search-bar dash_table_inputs" {...props.searchProps} />
                            </Col>
                            <Col md={8}>
                              <div className="dash_table_options_float_right">
                                <div className="dash_table_options_float_left" style={{ padding: "7px 10px 0 0" }}>
                                  From
                                </div>
                                <div className="dash_table_options_float_left">
                                  <FormControl
                                    value={this.state.start_date}
                                    onChange={this.handleChangeStartDate}
                                    className="dash_table_inputs"
                                    type="date"
                                  />
                                </div>
                                <div className="dash_table_options_float_left" style={{ padding: "7px 10px 0 10px" }}>
                                  To
                                </div>
                                <div className="dash_table_options_float_left">
                                  <FormControl
                                    value={this.state.end_date}
                                    onChange={this.handleChangeEndDate}
                                    min={this.state.start_date}
                                    className="dash_table_inputs"
                                    type="date"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {/* <div className="div-searchbar" style={{ float: "left", marginRight: "20px" }}></div>
                          <div className="select-sources"></div>
                          <div style={{ clear: "both" }}></div> */}
                        </Col>
                        <Col md={5}>
                          <Select
                            isMulti
                            value={this.state.selectedSources}
                            onChange={this.handleSourcesChange}
                            options={this.state.sourcesData}
                            placeholder={this.state.isLoading === true ? "LOADING ..." : "Select Sources"}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: "20px" }}>
                        <Col md={12}>
                          {this.state.isLoadingTableStats === true ? (
                            <div style={{ textAlign: "center" }}>
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <BootstrapTable
                              ref={(n) => (this.node = n)}
                              noDataIndication={() => <div style={{ textAlign: "center" }}>No Data available</div>}
                              wrapperClasses="table-responsive"
                              {...props.baseProps}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Dashboard;
