import React, { Component } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";

class ModalEditTrigger extends Component {
  state = {
    isCeiling1Enabled: false,
    isCeiling2Enabled: false,
    isCeiling3Enabled: false
  };

  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.handleC2Change = this.handleC2Change.bind(this);
    this.handleC3Change = this.handleC3Change.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }

  handleC2Change(evt) {
    this.setState({ isCeiling2Enabled: evt.target.checked });
  }

  handleC3Change(evt) {
    this.setState({ isCeiling3Enabled: evt.target.checked });
  }

  handleOpen() {
    if ("jsonData" in this.props.editTrigger)
      this.setState({
        isCeiling1Enabled: this.props.editTrigger.jsonData.ceiling_1_option,
        isCeiling2Enabled: this.props.editTrigger.jsonData.ceiling_2_option,
        isCeiling3Enabled: this.props.editTrigger.jsonData.ceiling_3_option
      });
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.props.onFormSubmitEditTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              defaultValue={this.props.editTrigger.TriggerName}
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            {"jsonData" in this.props.editTrigger && (
              <div style={{ fontSize: "13px", fontWeight: "500" }}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formMinScore">
                          <Form.Label>Min eScore</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="minEscore"
                            placeholder="Min eScore"
                            defaultValue={
                              "min_escore" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.min_escore !== ""
                                ? this.props.editTrigger.jsonData.min_escore
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formMaxScore">
                          <Form.Label>Max eScore</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="maxEscore"
                            placeholder="Maxn eScore"
                            defaultValue={
                              "max_escore" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.max_escore !== ""
                                ? this.props.editTrigger.jsonData.max_escore
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formMinRank">
                          <Form.Label>Min SalesRank</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="minRank"
                            placeholder="Min SalesRank"
                            defaultValue={
                              "min_rank" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.min_rank !== ""
                                ? this.props.editTrigger.jsonData.min_rank
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formMaxRank">
                          <Form.Label>Max SalesRank</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="maxRank"
                            placeholder="Maxn SalesRank"
                            defaultValue={
                              "max_rank" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.max_rank !== ""
                                ? this.props.editTrigger.jsonData.max_rank
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formFbaSlot">
                          <Form.Label>FBA Slot</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="fbaSlot"
                            placeholder="FBA Slot"
                            defaultValue={
                              "fba_slot" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.fba_slot !== ""
                                ? this.props.editTrigger.jsonData.fba_slot
                                : ""
                            }
                          />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Used Slot</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="usedSlot"
                            placeholder="Used Slot"
                            defaultValue={
                              "used_slot" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.used_slot !== ""
                                ? this.props.editTrigger.jsonData.used_slot
                                : ""
                            }
                          />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formAmzPer">
                          <Form.Label>Amazon Off %</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="amzOffPer"
                            placeholder="Amazon Off %"
                            defaultValue={
                              "amz_off_per" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.amz_off_per !== ""
                                ? this.props.editTrigger.jsonData.amz_off_per
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formTargetProfit">
                          <Form.Label>Target Profit</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="targetProfit"
                            placeholder="Target Profit"
                            defaultValue={
                              "target_profit" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.target_profit !== ""
                                ? this.props.editTrigger.jsonData.target_profit
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ height: "50px" }}>
                      <Col style={{ paddingTop: "8px" }} md={3}>
                        <Form.Group controlId="formIsCeiling1Compare">
                          <Form.Check
                            onChange={this.handleC1Change}
                            type="switch"
                            name="isCeiling1Enabled"
                            id="is-ceiling-1"
                            defaultChecked={
                              "ceiling_1_option" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.ceiling_1_option !== ""
                            }
                            label="Enable Ceiling 1?"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        {this.state.isCeiling1Enabled && (
                          <Form.Group controlId="formCeiling1Options">
                            <Form.Control
                              as="select"
                              name="ceiling1Options"
                              defaultValue={this.props.editTrigger.jsonData.ceiling_1_option}
                            >
                              <option value="">...</option>
                              <option value="Lowest New Price">Lowest New Price</option>
                              <option value="New Buy Box">New Buy Box</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={5}>
                        {this.state.isCeiling1Enabled && (
                          <Form.Group controlId="formAmzPer">
                            <Form.Control
                              required
                              type="number"
                              step="0.01"
                              name="ceiling1Discount"
                              placeholder="Ceiling 1 Discount %"
                              defaultValue={
                                "ceiling_1_discount" in this.props.editTrigger.jsonData &&
                                this.props.editTrigger.jsonData.ceiling_1_discount !== ""
                                  ? this.props.editTrigger.jsonData.ceiling_1_discount
                                  : ""
                              }
                            />
                          </Form.Group>
                        )}
                      </Col>
                    </Row>

                    <Row style={{ height: "50px" }}>
                      <Col style={{ paddingTop: "8px" }} md={3}>
                        <Form.Group controlId="formIsCeiling3Compare">
                          <Form.Check
                            onChange={this.handleC3Change}
                            type="switch"
                            name="isCeiling3Enabled"
                            defaultChecked={
                              "ceiling_3_option" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.ceiling_3_option !== ""
                            }
                            id="is-ceiling-3"
                            label="PrimeLess?"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        {this.state.isCeiling3Enabled && (
                          <Form.Group controlId="formCeiling3Options">
                            <Form.Control
                              style={{ fontSize: "13px" }}
                              as="select"
                              name="ceiling3Options"
                              defaultValue={this.props.editTrigger.jsonData.ceiling_3_option}
                            >
                              <option>...</option>
                              <option>Lowest Used Offer</option>
                              <option>2nd Lowest Used Offer</option>
                              <option>3rd Lowest Used Offer</option>
                              <option>Average of 3 Used Offers</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={2}>
                        {this.state.isCeiling3Enabled && (
                          <Form.Group controlId="formAmzPer">
                            <Form.Control
                              required
                              style={{ fontSize: "13px" }}
                              type="number"
                              step="0.01"
                              name="ceiling3BumpUpDollars"
                              defaultValue={
                                "ceiling_3_bump_up_dollars" in this.props.editTrigger.jsonData
                                  ? this.props.editTrigger.jsonData.ceiling_3_bump_up_dollars
                                  : ""
                              }
                              placeholder="Bump$"
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={2}>
                        {this.state.isCeiling3Enabled && (
                          <Form.Group controlId="formAmzPer">
                            <Form.Control
                              required
                              style={{ fontSize: "13px" }}
                              type="number"
                              step="0.01"
                              name="ceiling3BumpUpPercentage"
                              defaultValue={
                                "ceiling_3_bump_up_percentage" in this.props.editTrigger.jsonData
                                  ? this.props.editTrigger.jsonData.ceiling_3_bump_up_percentage
                                  : 0
                              }
                              placeholder="Bump%"
                            />
                          </Form.Group>
                        )}
                      </Col>
                    </Row>

                    <Row style={{ height: "50px" }}>
                      <Col style={{ paddingTop: "8px" }} md={3}>
                        <Form.Group controlId="formIsCeiling2Compare">
                          <Form.Check
                            onChange={this.handleC2Change}
                            type="switch"
                            name="isCeiling2Enabled"
                            id="is-ceiling-2"
                            label="Enable Ceiling 2?"
                            defaultChecked={
                              "ceiling_2_option" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.ceiling_2_option !== ""
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        {this.state.isCeiling2Enabled && (
                          <Form.Group controlId="formCeiling2Options">
                            <Form.Control
                              as="select"
                              name="ceiling2Options"
                              style={{ fontSize: "13px" }}
                              defaultValue={this.props.editTrigger.jsonData.ceiling_2_option}
                            >
                              <option>...</option>
                              <option>Lowest Used Offer</option>
                              <option>2nd Lowest Used Offer</option>
                              <option>3rd Lowest Used Offer</option>
                              <option>Average of 3 Used Offers</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={2}>
                        {this.state.isCeiling2Enabled && (
                          <Form.Group controlId="formAmzPer">
                            <Form.Control
                              required
                              style={{ fontSize: "13px" }}
                              type="number"
                              step="0.01"
                              name="ceiling2BumpUpDollars"
                              defaultValue={
                                "ceiling_2_bump_up_dollars" in this.props.editTrigger.jsonData
                                  ? this.props.editTrigger.jsonData.ceiling_2_bump_up_dollars
                                  : ""
                              }
                              placeholder="Bump$"
                            />
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={2}>
                        {this.state.isCeiling2Enabled && (
                          <Form.Group controlId="formAmzPer">
                            <Form.Control
                              required
                              style={{ fontSize: "13px" }}
                              type="number"
                              step="0.01"
                              name="ceiling2BumpUpPercentage"
                              defaultValue={
                                "ceiling_2_bump_up_percetange" in this.props.editTrigger.jsonData
                                  ? this.props.editTrigger.jsonData.ceiling_2_bump_up_percetange
                                  : ""
                              }
                              placeholder="Bump%"
                            />
                          </Form.Group>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formIsBBCompare">
                          <Form.Check
                            type="switch"
                            name="isBBCompare"
                            id="bb-compare"
                            label="BB Compare?"
                            defaultChecked={this.props.editTrigger.jsonData.bb_compare}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Check
                          type="switch"
                          name="isAlwaysReject"
                          id="always-reject"
                          label="Always Reject?"
                          defaultChecked={this.props.editTrigger.jsonData.always_reject}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalEditTrigger;
