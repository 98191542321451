import { apiBaseUrlLocal } from "./AppConfig";

export const submitLogin = async (username, password) => {
  const response = await fetch(apiBaseUrlLocal + "admin/login", {
    method: "POST",
    body: new URLSearchParams({
      username: username,
      password: password
    })
  });
  const data = await response.json();
  return data;
};
