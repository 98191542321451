import React, { Component } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";

class ModalAddTrigger extends Component {
  state = {
    isCeiling1Enabled: false,
    isCeiling2Enabled: false,
    isCeiling3Enabled: false
  };
  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.handleC2Change = this.handleC2Change.bind(this);
    this.handleC3Change = this.handleC3Change.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }
  handleC2Change(evt) {
    this.setState({ isCeiling2Enabled: evt.target.checked });
  }
  handleC3Change(evt) {
    this.setState({ isCeiling3Enabled: evt.target.checked });
  }

  handleOpen() {
    this.setState({ isCeiling1Enabled: false, isCeiling2Enabled: false });
  }

  onFormSubmitAddTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const isCeiling1Options = event.target.elements.isCeiling1Enabled.checked;

    const triggerJson = {
      min_escore: event.target.elements.minEscore.value,
      max_escore: event.target.elements.maxEscore.value,
      min_rank: event.target.elements.minRank.value,
      max_rank: event.target.elements.maxRank.value,
      used_slot: event.target.elements.usedSlot.value,
      always_reject: event.target.elements.isAlwaysReject.checked,
      amz_off_per: event.target.elements.amzOffPer.value,
      target_profit: event.target.elements.targetProfit.value,
      ceiling_1_option: isCeiling1Options ? event.target.elements.ceiling1Options.value : "",
      ceiling_1_discount: isCeiling1Options ? event.target.elements.ceiling1Discount.value : 0
    };

    this.props.onFormSubmitAddTrigger(triggerName, triggerJson);
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={3}>
                      <Form.Group controlId="formMinScore">
                        <Form.Label>Min eScore</Form.Label>
                        <Form.Control required type="number" step="0.01" name="minEscore" />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formMaxScore">
                        <Form.Label>Max eScore</Form.Label>
                        <Form.Control required type="number" step="0.01" name="maxEscore" />
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group controlId="formMinRank">
                        <Form.Label>Min SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="minRank" />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formMaxRank">
                        <Form.Label>Max SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="maxRank" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row></Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId="formUsedSlot">
                        <Form.Label>Used Slot</Form.Label>
                        <Form.Control required type="number" step="0.01" name="usedSlot" placeholder="Used Slot" />
                        <Form.Text className="text-muted">
                          Enter <strong>-1</strong> to disable.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formAmzPer">
                        <Form.Label>Amazon Off %</Form.Label>
                        <Form.Control required type="number" step="0.01" name="amzOffPer" placeholder="Amazon Off %" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formTargetProfit">
                        <Form.Label>Target Profit ($)</Form.Label>
                        <Form.Control
                          style={{ background: "#007bff", color: "#fff", fontWeight: "bold" }}
                          required
                          type="number"
                          step="0.01"
                          name="targetProfit"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ height: "50px" }}>
                    <Col style={{ paddingTop: "8px" }} md={3}>
                      <Form.Group controlId="formIsCeiling1Compare">
                        <Form.Check
                          onChange={this.handleC1Change}
                          type="switch"
                          name="isCeiling1Enabled"
                          id="is-ceiling-1"
                          label="Ceiling 1?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {this.state.isCeiling1Enabled && (
                        <Form.Group controlId="formCeiling1Options">
                          <Form.Control as="select" name="ceiling1Options">
                            <option>...</option>
                            <option>Lowest New Price</option>
                            <option>New Buy Box</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={5}>
                      {this.state.isCeiling1Enabled && (
                        <Form.Group controlId="formAmzPer">
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling1Discount"
                            placeholder="Ceiling 1 Discount %"
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Check type="switch" name="isAlwaysReject" id="always-reject" label="Always Reject?" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTrigger;
