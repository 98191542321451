import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Tabs, DragTabList, DragTab, PanelList, Panel } from "react-tabtab";
import { simpleSwitch } from "react-tabtab/lib/helpers/move";
import * as customStyle from "react-tabtab/lib/themes/bulma";

import RMSTrigger from "../Level2/RMSTrigger";
import SBYBTrigger from "../Level2/SBYBTrigger";
import BRTrigger from "../Level2/BRTrigger";
import BRBTrigger from "../Level2/BRBTrigger";
import SBTrigger from "../Level2/SBTrigger";
import ZIFFITTrigger from "../Level2/ZIFFITTrigger";
import { processTabSequenceChange } from "../../../includes/HelperTriggerBatches";

class WholeSaleTriggers extends Component {
  state = {
    activeIndex: 0,
  };

  constructor(props) {
    super(props);
    this.handleTabSequenceChange = this.handleTabSequenceChange.bind(this);
  }

  getTabs() {
    let tabs = [
      {
        title: `RMS`,
        content: (
          <RMSTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `SBYB`,
        content: (
          <SBYBTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `BR`,
        content: (
          <BRTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `SB`,
        content: (
          <SBTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `ZIFFIT`,
        content: (
          <ZIFFITTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `BRB`,
        content: (
          <BRBTrigger
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
    ];

    let tabsInOrder = [];

    const wholeSaleConfiguration =
      "conf_wholesale" in this.props.batchConfiguration &&
      this.props.batchConfiguration.conf_wholesale !== ""
        ? JSON.parse(this.props.batchConfiguration.conf_wholesale)
        : [];

    wholeSaleConfiguration.forEach((tabName) => {
      var index = tabs.findIndex(function (tab) {
        return tab.title === tabName.module_name;
      });

      if (index >= 0) tabsInOrder.push(tabs.splice(index, 1)[0]);
    });

    tabsInOrder = tabsInOrder.concat(tabs);
    return tabsInOrder;
  }

  async handleTabSequenceChange({ oldIndex, newIndex }) {
    const updateTabs = simpleSwitch(this.getTabs(), oldIndex, newIndex);
    this.setState({ activeIndex: newIndex });
    await processTabSequenceChange(
      updateTabs,
      "conf_wholesale",
      this.props.admin_data,
      this.props.batchConfiguration,
      this.props.triggerBatchId,
      this.props.updateTBConfig
    );
  }

  render() {
    const { activeIndex } = this.state;
    const tabs = this.getTabs();

    const tabsTemplate = [];
    const panelTemplate = [];
    tabs.forEach((tab, index) => {
      tabsTemplate.push(<DragTab key={index}>{tab.title}</DragTab>);
      panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
    });

    let isMEnabled = false;
    if (
      "high_level" in this.props.batchConfiguration &&
      this.props.batchConfiguration.high_level !== null
    ) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function (module) {
        return module.module_name === "WholeSale";
      });
      isMEnabled = config_high_level[index].enabled;
    }

    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row>
              <Col md={12}>
                <Tabs
                  showModalButton={false}
                  customStyle={customStyle}
                  activeIndex={activeIndex}
                  onTabChange={(index) => this.setState({ activeIndex: index })}
                  onTabSequenceChange={this.handleTabSequenceChange}
                >
                  <DragTabList>{tabsTemplate}</DragTabList>
                  <PanelList>{panelTemplate}</PanelList>
                </Tabs>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default WholeSaleTriggers;
