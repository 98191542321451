import React, { Component } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { apiBaseUrlLocal } from "../../../../includes/AppConfig";

class ModalSettings extends Component {
  state = {
    currentScale: "Percentage (%)",
    noUsedFallback: false,
    settings: {},
    isLoadingSettings: true,
    isSaving: false,
  };

  constructor() {
    super();
    this.onFormSubmitSaveSettings = this.onFormSubmitSaveSettings.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.fetchSettings = this.fetchSettings.bind(this);
    this.toggleFallback = this.toggleFallback.bind(this);
  }

  async fetchSettings() {
    this.setState({ isLoadingSettings: true });
    const response = await fetch(
      `${apiBaseUrlLocal}admin/tbatches/getsettings/${this.props.triggerBatchId}`,
      {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.props.admin_data.admin_id,
          app_token: this.props.admin_data.token,
          trigger_type: this.props.triggerTypeId,
        }),
      }
    );
    if (response.status === 200) {
      const settings_raw = await response.json();
      const noUsedFallback = "no_used_fallback_val" in settings_raw.data ? false : true;
      this.setState({ settings: settings_raw.data, noUsedFallback: noUsedFallback });
    }
    this.setState({ isLoadingSettings: false });
  }

  // onChangeFallback(e) {
  //   this.setState({
  //     currentScale:
  //       e.target.value !== "" && e.target.value.indexOf("per") >= 0
  //         ? "Percentage (%)"
  //         : "Price ($)",
  //     noUsedFallback: e.target.value === "" ? false : true,
  //   });
  // }

  async onFormSubmitSaveSettings(e) {
    e.preventDefault();
    const fallbackVal =
      this.state.noUsedFallback === true
        ? ""
        : "fallbackVal" in e.target.elements
        ? e.target.elements.fallbackVal.value
        : "";
    const fallbackValFixed =
      "fallbackValFixed" in e.target.elements ? e.target.elements.fallbackValFixed.value : "";

    this.setState({ isSaving: true });

    const response = await fetch(
      `${apiBaseUrlLocal}admin/tbatches/settings/${this.props.triggerBatchId}`,
      {
        method: "POST",
        body: new URLSearchParams({
          user_id: this.props.admin_data.admin_id,
          app_token: this.props.admin_data.token,
          trigger_type: this.props.triggerTypeId,
          fallback_val: fallbackVal,
          fallback_val_fixed: fallbackValFixed,
        }),
      }
    );
    this.setState({ isSaving: false });

    if (response.status === 200) {
      this.props.handleClose();
    } else return false;
  }

  handleOpen() {
    this.fetchSettings();
  }

  toggleFallback(e) {
    this.setState({ noUsedFallback: e.target.checked });
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showSettingsModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Settings</h3>
        </Modal.Header>
        {this.state.isLoadingSettings === true ? (
          <div style={{ padding: "50px" }}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        ) : (
          <Form onSubmit={this.onFormSubmitSaveSettings}>
            <Modal.Body>
              <div>
                <Form.Check
                  type="switch"
                  name="isActive"
                  onChange={this.toggleFallback}
                  defaultChecked={this.state.noUsedFallback}
                  id="is-active"
                  style={{ FontWeight: "bold" }}
                  label="Disable Missing Offers Trigger?"
                />
              </div>
              {this.state.noUsedFallback === false && (
                <div>
                  <div style={{ marginTop: "25px" }}>
                    <div style={{ float: "left", padding: "5px 5px 5px 0" }}>
                      If there are no used offers, set the target price to be{" "}
                    </div>
                    <div style={{ float: "left" }}>
                      <Form.Control
                        required
                        type="number"
                        step="0.01"
                        name="fallbackVal"
                        max={100}
                        style={{
                          width: "80px",
                          borderRadius: "0",
                          border: "none",
                          borderBottom: "1px solid #ccc",
                        }}
                        defaultValue={
                          "no_used_fallback_val" in this.state.settings
                            ? this.state.settings.no_used_fallback_val
                            : ""
                        }
                      />
                    </div>
                    <div style={{ float: "left", padding: "5px 0 5px 0" }}>
                      % of the lowest new price
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>

                  <div style={{ marginTop: "15px" }}>
                    <div style={{ float: "left", padding: "5px 5px 5px 0" }}>
                      If there are no new offers either, set the target price to be a fixed price of
                      $
                    </div>
                    <div style={{ float: "left" }}>
                      <Form.Control
                        required
                        type="number"
                        step="0.01"
                        name="fallbackValFixed"
                        max={100}
                        style={{
                          width: "80px",
                          borderRadius: "0",
                          border: "none",
                          borderBottom: "1px solid #ccc",
                        }}
                        defaultValue={
                          "no_used_fallback_val_fixed" in this.state.settings
                            ? this.state.settings.no_used_fallback_val_fixed
                            : ""
                        }
                      />
                    </div>

                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {this.state.isSaving === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ModalSettings;
