import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { reconfigTB } from "../../../includes/HelperTriggerBatches";

class BookScouterTrigger extends Component {
  state = {
    isTriggerEnabled: false,
    config_bs: [],
    targetDiff: 0
  };

  constructor(props) {
    super(props);
    this.handleReConfig = this.handleReConfig.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId });

    if (
      "conf_bookscouter" in this.props.batchConfiguration &&
      this.props.batchConfiguration.conf_bookscouter !== null
    ) {
      const config_bs = JSON.parse(this.props.batchConfiguration.conf_bookscouter);
      this.setState({ config_bs });

      if ("enabled" in config_bs) {
        this.setState({ isTriggerEnabled: config_bs.enabled, targetDiff: config_bs.target_diff });
      }
    }
  }

  async handleReConfig() {
    let newConfig = {};
    const targetDiff = this.state.targetDiff;
    if (targetDiff >= 0) {
      newConfig.enabled = true;
      newConfig.target_diff = targetDiff;
      this.setState({ isTriggerEnabled: true });
    } else {
      newConfig.enabled = false;
      this.setState({ isTriggerEnabled: false });
    }

    await reconfigTB(this.props.admin_data, this.props.triggerBatchId, "conf_bookscouter", JSON.stringify(newConfig));
  }

  render() {
    let isMEnabled = false;
    if ("high_level" in this.props.batchConfiguration && this.props.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.props.batchConfiguration.high_level);
      const index = config_high_level.findIndex(function(module) {
        return module.module_name === "BookScouter";
      });
      isMEnabled = config_high_level[index].enabled;
    }

    return (
      <React.Fragment>
        {isMEnabled === true && (
          <Container fluid={true} style={{ padding: "0" }}>
            <Row style={{ marginTop: "20px", paddingBottom: "15px" }}>
              <Col md={12}>No sub modules available</Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default BookScouterTrigger;
