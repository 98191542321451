import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import { Row, Col, Container, Button, Tabs, Tab, Form, Spinner } from "react-bootstrap";
import Cookies from "universal-cookie";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "../components/Payments/CardForm";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const cookies = new Cookies();
const stripePromise = loadStripe("pk_live_95XTUuVdBctIMUeuNt517vF2");

class Settings extends Component {
  state = {
    showAddModal: false,
    admin_data: cookies.get("admin_data"),
    statsTime: "00:00",
    isStatsDaily: false,
    isLoading: true,
    isStatsWeekly: false,
    statsEmail: "",
    isStatsMonthly: false,
    isUpdatingSettings: false,
    isUpdatingSubscription: false,
    currentPlanName: "",
    cardErrorMessage: "",
    subscriptionJSON: {},
    teamMembersCount: 0,
    soundApiURL: apiBaseUrlLocal.replace("/v1", ""),
  };

  constructor() {
    super();
    this.onChangeStatsTime = this.onChangeStatsTime.bind(this);
    this.onFormSubmitSaveEmailSettings = this.onFormSubmitSaveEmailSettings.bind(this);
    this.onFormSubmitUpdatePlan = this.onFormSubmitUpdatePlan.bind(this);
    this.updateAdminData = this.updateAdminData.bind(this);
    this.updateCardErrorMessage = this.updateCardErrorMessage.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  componentDidMount() {
    this.loadSettings();
  }

  onChangeStatsTime = (time) => {
    console.log(time);
    this.setState({ statsTime: time });
  };

  updateCardErrorMessage = (cardErrorMessage) => {
    this.setState({ cardErrorMessage });
  };

  postSettings = async (settings_json) => {
    this.setState({ isUpdatingSettings: true });
    const response = await fetch(apiBaseUrlLocal + "admin/teams/settings", {
      method: "PUT",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        settings_json: settings_json,
      }),
    });
    this.setState({ isUpdatingSettings: false });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else return false;
  };

  postUpdatePlan = async () => {
    this.setState({ isUpdatingSubscription: true });
    const response = await fetch(apiBaseUrlLocal + "admin/payments/update-subscription", {
      method: "PUT",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        plan_name: this.state.currentPlanName,
      }),
    });
    this.setState({ isUpdatingSubscription: false });
    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
    } else return false;
  };

  getSettings = async () => {
    this.setState({ isLoading: true });
    const responseGetSubscription = await fetch(apiBaseUrlLocal + "admin/payments/get-subscription", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (responseGetSubscription.status === 200) {
      const subData = await responseGetSubscription.json();
      this.setState({ subscriptionJSON: subData.data });
    }

    const response = await fetch(apiBaseUrlLocal + "admin/teams/get_settings", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    this.setState({ isLoading: false });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else return false;
  };

  async loadSettings() {
    const settingsJsonUnparsed = await this.getSettings();
    console.log(settingsJsonUnparsed);
    if (settingsJsonUnparsed !== false && "settings" in settingsJsonUnparsed.data) {
      const settingsJson = JSON.parse(settingsJsonUnparsed.data.settings);
      const emailSettings = settingsJson.emailSettings;
      console.log(emailSettings);
      this.setState({
        isStatsDaily: emailSettings.isStatsDaily,
        isStatsWeekly: emailSettings.isStatsWeekly,
        isStatsMonthly: emailSettings.isStatsMonthly,
        statsEmail: emailSettings.statsEmail,
        statsTime: emailSettings.statsTime,
        teamMembersCount: settingsJsonUnparsed.data.team_member_count,
      });
    }
  }

  async onFormSubmitSaveEmailSettings(event) {
    event.preventDefault();
    const statsEmail = "statsEmail" in event.target ? event.target.statsEmail.value : "";
    const statsTime = parseInt(this.state.statsTime);
    const [isStatsDaily, isStatsWeekly, isStatsMonthly] = [
      this.state.isStatsDaily,
      this.state.isStatsWeekly,
      this.state.isStatsMonthly,
    ];
    const settingsJson = {
      emailSettings: {
        statsEmail,
        statsTime,
        isStatsDaily,
        isStatsWeekly,
        isStatsMonthly,
      },
    };
    await this.postSettings(JSON.stringify(settingsJson));
  }

  async onFormSubmitUpdatePlan(event) {
    event.preventDefault();

    await this.postUpdatePlan();
    window.location.reload();
  }

  handleChange = (frequency, e) => {
    if (frequency === "daily") this.setState({ isStatsDaily: e.target.checked });
    if (frequency === "weekly") this.setState({ isStatsWeekly: e.target.checked });
    if (frequency === "monthly") this.setState({ isStatsMonthly: e.target.checked });
  };

  updateAdminData = (newAdminData) => {
    this.setState({ admin_data: newAdminData }, () => {
      this.getSettings();
    });
    cookies.set("admin_data", newAdminData, { path: "/" });
  };

  cancelSubscription() {
    confirmAlert({
      title: "Are you sure you want to cancel?",
      message: `We're sorry to see you go! If you continue your cancellation it will become effective at the end of your current billing cycle: ${new Date(
        this.state.subscriptionJSON.nextInvoiceDate * 1000
      ).toDateString()}. You can rejoin any time - all your team and scan data will be archived.`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            this.setState({ isLoading: true });
            const responseGetSubscription = await fetch(apiBaseUrlLocal + "admin/payments/cancel-subscription", {
              method: "POST",
              body: new URLSearchParams({
                user_id: this.state.admin_data.admin_id,
                app_token: this.state.admin_data.token,
              }),
            });
            if (responseGetSubscription.status === 200) {
              await responseGetSubscription.json();
              // this.setState({ subscriptionJSON: {} });
              this.getSettings();
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    let hours = [];
    for (let i = 0; i < 24; i += 4) hours.push(i);
    return (
      <React.Fragment>
        <TopMenu activeKey="/account-settings" />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <h2>Account</h2>

              <Tabs defaultActiveKey="settings" id="account-settings-tabs" style={{ marginTop: "25px" }}>
                <Tab eventKey="settings" title="Settings">
                  <Form inline onSubmit={this.onFormSubmitSaveEmailSettings}>
                    <div style={{ marginTop: "30px" }}>
                      <h4 style={{ marginBottom: "20px" }}>Email Notifications</h4>
                      <p>Enable to receive daily, weekly or monthly scan statistics on email.</p>

                      <div>
                        <Form.Check
                          inline
                          type="switch"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          name="isDailyStats"
                          id="daily-stats"
                          checked={this.state.isStatsDaily}
                          onChange={(e) => this.handleChange("daily", e)}
                          label="Every Day?"
                        />
                        <Form.Check
                          inline
                          type="switch"
                          name="isWeeklyStats"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          id="weekly-stats"
                          checked={this.state.isStatsWeekly}
                          onChange={(e) => this.handleChange("weekly", e)}
                          label="Every Monday?"
                        />
                        <Form.Check
                          inline
                          type="switch"
                          name="isMonthlyStats"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          id="monthly-stats"
                          checked={this.state.isStatsMonthly}
                          onChange={(e) => this.handleChange("monthly", e)}
                          label="On the first of every month?"
                        />
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Time: </span>
                        <Form.Control
                          as="select"
                          name="statsTime"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          value={this.state.statsTime}
                          onChange={(e) => this.setState({ statsTime: e.target.value })}
                        >
                          {hours.map((hour) => {
                            return <option value={hour}>{hour + ":00"}</option>;
                          })}
                        </Form.Control>
                        <span style={{ marginLeft: "15px" }}>(PST)</span>
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Email Address: </span>
                        <Form.Control
                          inline
                          required
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          type="text"
                          name="statsEmail"
                          value={this.state.statsEmail}
                          onChange={(e) => this.setState({ statsEmail: e.target.value })}
                          style={{ width: "300px" }}
                        />
                      </div>

                      <div style={{ marginTop: "31px" }}>
                        <Button
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          variant="primary"
                          type="submit"
                        >
                          {this.state.isUpdatingSettings === true ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab>
                <Tab
                  eventKey="billing"
                  title="Subscription"
                  disabled={
                    window.location.hostname === "localhost" ||
                    window.location.hostname === "admin.palletiq.com" ||
                    window.location.hostname === "piq-admin.lavneet.com"
                      ? false
                      : true
                  }
                >
                  <div style={{ marginTop: "40px" }}>
                    <h5>Current Subscription</h5>
                    <div>
                      <b>Plan Name: </b>{" "}
                      {this.state.isLoading ? (
                        "Fetching ..."
                      ) : "planName" in this.state.subscriptionJSON && this.state.subscriptionJSON.planName !== "" ? (
                        this.state.subscriptionJSON.planName +
                        " X " +
                        this.state.subscriptionJSON.userQuantity +
                        " users"
                      ) : (
                        <span style={{ color: "red" }}>NO PLAN</span>
                      )}
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <b>Subscription Status: </b>{" "}
                      {this.state.isLoading ? (
                        "Fetching ..."
                      ) : "subData" in this.state.subscriptionJSON &&
                        "cancel_at_period_end" in this.state.subscriptionJSON.subData &&
                        this.state.subscriptionJSON.subData.cancel_at_period_end === true ? (
                        <span style={{ color: "orange" }}>
                          Will cancel on{" "}
                          {new Date(this.state.subscriptionJSON.subData.current_period_end * 1000).toDateString()}
                        </span>
                      ) : "subStatus" in this.state.subscriptionJSON &&
                        this.state.subscriptionJSON.subStatus !== "" &&
                        this.state.subscriptionJSON.subStatus === "active" ? (
                        <span style={{ color: "green", fontWeight: "bold" }}>ACTIVE</span>
                      ) : "subStatus" in this.state.subscriptionJSON &&
                        this.state.subscriptionJSON.subStatus !== "" &&
                        this.state.subscriptionJSON.subStatus !== "active" ? (
                        <span style={{ color: "red", fontWeight: "bold" }}>INACTIVE</span>
                      ) : (
                        <span style={{ color: "red" }}>NO PLAN</span>
                      )}
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <b>Next Invoice Date: </b>{" "}
                      {this.state.isLoading ? (
                        "Fetching ..."
                      ) : "nextInvoiceDate" in this.state.subscriptionJSON &&
                        this.state.subscriptionJSON.nextInvoiceDate !== "" ? (
                        new Date(this.state.subscriptionJSON.nextInvoiceDate * 1000).toDateString()
                      ) : (
                        <span style={{ color: "red" }}>N/A</span>
                      )}
                    </div>
                    <div style={{ marginTop: "8px" }}>
                      <b>Next Invoice Amount: </b>{" "}
                      {this.state.isLoading ? (
                        "Fetching ..."
                      ) : "nextInvoiceAmount" in this.state.subscriptionJSON &&
                        this.state.subscriptionJSON.nextInvoiceAmount !== "" ? (
                        "$" +
                        this.state.subscriptionJSON.nextInvoiceAmount / 100 +
                        ("nextInvoicePDF" in this.state.subscriptionJSON ? (
                          <span>
                            <a href={this.state.subscriptionJSON.nextInvoicePDF}>(Click here to download PDF)</a>
                          </span>
                        ) : (
                          ""
                        ))
                      ) : (
                        <span style={{ color: "red" }}>N/A</span>
                      )}
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      {this.state.isLoading === false &&
                        "planName" in this.state.subscriptionJSON &&
                        this.state.subscriptionJSON.planName !== "" &&
                        (!("subData" in this.state.subscriptionJSON) ||
                          !("cancel_at_period_end" in this.state.subscriptionJSON.subData) ||
                          this.state.subscriptionJSON.subData.cancel_at_period_end === false) && (
                          <Button
                            variant="danger"
                            disabled={this.state.isUpdatingSubscription}
                            onClick={this.cancelSubscription}
                          >
                            {this.state.isUpdatingSubscription === true ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              "Cancel Subscription"
                            )}
                          </Button>
                        )}
                    </div>
                  </div>
                  <Form onSubmit={this.onFormSubmitUpdatePlan}>
                    <div
                      style={{
                        marginTop: "30px",
                        width: "1080px",
                        maxWidth: "100%",
                        borderTop: "1px solid #ccc",
                        paddingTop: "15px",
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <h5>Credit Card</h5>
                          <div
                            style={{
                              height: "50px",
                              display: this.state.cardErrorMessage === "" ? "none" : "block",
                            }}
                          >
                            {this.state.cardErrorMessage !== "" && (
                              <div style={{ color: "red" }}>{this.state.cardErrorMessage}</div>
                            )}
                          </div>
                          <div
                            style={{
                              height: "50px",
                              display: this.state.cardErrorMessage === "" ? "block" : "none",
                            }}
                          >
                            {this.state.cardErrorMessage === "" &&
                              this.state.admin_data.card_last4 !== "" &&
                              this.state.admin_data.card_last4 !== null && (
                                <div>
                                  <b>Current Card: </b>{" "}
                                  {"card_brand" in this.state.admin_data && this.state.admin_data.card_brand !== null
                                    ? this.state.admin_data.card_brand.toUpperCase() +
                                      " ENDING IN " +
                                      this.state.admin_data.card_last4.toString()
                                    : "NO CARD"}
                                </div>
                              )}
                          </div>
                          <Elements stripe={stripePromise}>
                            <CardForm
                              updateCardErrorMessage={this.updateCardErrorMessage}
                              isAddingCard={this.state.isAddingCard}
                              admin_data={this.state.admin_data}
                              updateAdminData={this.updateAdminData}
                            />
                          </Elements>
                        </Col>
                        {/* <Col md={6}>
                          <h5>Choose Plan</h5>
                          <div style={{ height: "50px" }}>
                            {this.state.admin_data.card_last4 !== "" && (
                              <div>
                                <b>Current Plan: </b>{" "}
                                {this.state.isLoading ? (
                                  "... ... ..."
                                ) : "planName" in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.planName !== "" ? (
                                  this.state.subscriptionJSON.planName +
                                  " ($" +
                                  (99 + 19 * (this.state.teamMembersCount - 1)) +
                                  "/mo) includes " +
                                  this.state.teamMembersCount +
                                  " team members"
                                ) : (
                                  <span style={{ color: "red" }}>NO PLAN</span>
                                )}
                              </div>
                            )}
                          </div>
                          {this.state.admin_data.stripe_payment_id !== "" &&
                          this.state.admin_data.stripe_payment_id !== null ? (
                            <div>
                              <Form.Control
                                as="select"
                                style={{ height: "41px" }}
                                name="currentPlanName"
                                disabled={this.state.isLoading || this.state.isUpdatingSubscription}
                                value={this.state.currentPlanName}
                                onChange={(e) => this.setState({ currentPlanName: e.target.value })}
                              >
                                <option value="PIQ_LITE_BETA">PalletIQ Lite - Beta ($99 + $19/user)</option>
                              </Form.Control>
                              <div style={{ marginTop: "20px" }}>
                                <Button variant="primary" type="submit" disabled={this.state.isUpdatingSubscription}>
                                  {this.state.isUpdatingSubscription === true ? (
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </Spinner>
                                  ) : (
                                    "Update Plan"
                                  )}
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div style={{ paddingTop: "7px" }}>Please add a credit card to see the plans.</div>
                          )}
                        </Col> */}
                      </Row>
                    </div>
                  </Form>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Settings;
