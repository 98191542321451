import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TriggerBatches from "./pages/TriggerBatches";
import Route404 from "./pages/Route404";
import "./assets/style/theme_minimal.css";
import Dashboard from "./pages/Dashboard";
import TriggerBatch from "./pages/TriggerBatch";
import Users from "./pages/Users";
import Login from "./pages/Login";
import Sounds from "./pages/Sounds";
import Scans from "./pages/Scans";
import ShippingPlans from "./pages/ShippingPlans";
import Settings from "./pages/Settings";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/triggers" component={TriggerBatches} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/sounds" component={Sounds} />
            <Route exact path="/scans" component={Scans} />
            <Route exact path="/account-settings" component={Settings} />
            <Route exact path="/shipping-plans" component={ShippingPlans} />
            <Route exact path="/edit-trigger-batch/:id/:name" component={TriggerBatch} />
            <Route component={Route404} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
