import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { reconfigTB } from "../../../includes/HelperTriggerBatches";

class ZIFFITTrigger extends Component {
  state = {
    isTriggerEnabled: false,
    config_wholesale: [],
    targetDiff: 0,
    thisTabIndex: -1,
    reconfigInProgress: false,
  };

  constructor(props) {
    super(props);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.toggleCurrent = this.toggleCurrent.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId });

    if (
      "conf_wholesale" in this.props.batchConfiguration &&
      this.props.batchConfiguration.conf_wholesale !== null
    ) {
      const config_wholesale = JSON.parse(this.props.batchConfiguration.conf_wholesale);
      this.setState({ config_wholesale });

      console.log(config_wholesale);

      const index = config_wholesale.findIndex(function (module) {
        return module.module_name === "ZIFFIT";
      });
      this.setState({ thisTabIndex: index });

      const current_config = config_wholesale[index];
      console.log(current_config);

      if ("enabled" in current_config) {
        this.setState({
          isTriggerEnabled: current_config.enabled,
          targetDiff: current_config.target_diff,
        });
      }
    }
  }

  async handleReConfig() {
    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function (module) {
      return module.module_name === "ZIFFIT";
    });
    this.setState({ reconfigInProgress: true });
    const targetDiff = this.state.targetDiff;
    if (targetDiff >= 0) {
      config_wholesale_clone[index].enabled = true;
      config_wholesale_clone[index].target_diff = parseFloat(targetDiff);
      this.setState({ isTriggerEnabled: true });
    } else {
      config_wholesale_clone[index].enabled = false;
      this.setState({ isTriggerEnabled: false });
    }
    this.props.updateTBConfig("conf_wholesale", config_wholesale_clone);
    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      "conf_wholesale",
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  async toggleCurrent(e) {
    this.setState({ isTriggerEnabled: e.target.checked, reconfigInProgress: true });

    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function (module) {
      return module.module_name === "ZIFFIT";
    });
    config_wholesale_clone[index].enabled = e.target.checked;
    this.setState({ isTriggerEnabled: e.target.checked });

    this.props.updateTBConfig("conf_wholesale", config_wholesale_clone);

    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      "conf_wholesale",
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid={true} style={{ padding: "0" }}>
          <Row style={{ marginTop: "20px", paddingBottom: "15px" }}>
            <Col md={5}>
              <div style={{ float: "left", padding: "7px 50px 0 0" }}>
                <Form.Check
                  onChange={this.toggleCurrent}
                  checked={this.state.isTriggerEnabled}
                  type="switch"
                  disabled={this.state.reconfigInProgress}
                  id="enable-trigger"
                  label="Enable?"
                />
              </div>

              {this.state.isTriggerEnabled === true && this.state.thisTabIndex > 0 && (
                <InputGroup id="ig_asin" style={{ width: "250px" }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={this.state.targetDiff}
                    onChange={(e) => {
                      this.setState({ targetDiff: e.target.value });
                    }}
                    autoComplete="off"
                    id="targetDiffInput"
                    placeholder="Difference"
                    type="number"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Append id="btn_asin_search">
                    <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                      {this.state.reconfigInProgress === true ? (
                        <Spinner
                          style={{ width: "20px", height: "20px" }}
                          animation="border"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ZIFFITTrigger;
