import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Container, Row, Col, Spinner, FormControl, Form } from "react-bootstrap";
import Cookies from "universal-cookie";
import { getTBConfiguration, reconfigTB } from "../includes/HelperTriggerBatches";
import { Tabs, DragTabList, DragTab, PanelList, Panel } from "react-tabtab";
import { simpleSwitch } from "react-tabtab/lib/helpers/move";
import * as customStyle from "react-tabtab/lib/themes/bootstrap";

// Import Level 1 Trigger Module Components
import AmazonTriggers from "../components/TriggerBatch/Level1/AmazonTriggers";
import WhaleSaleTrigger from "../components/TriggerBatch/Level1/WhaleSaleTrigger";
import HitListTriggers from "../components/TriggerBatch/Level1/HitListTriggers";
import WholeSaleTriggers from "../components/TriggerBatch/Level1/WholeSaleTriggers";
import BookScouterTrigger from "../components/TriggerBatch/Level1/BookScouterTrigger";

const cookies = new Cookies();

class TriggerBatch extends Component {
  state = {
    triggerBatchId: this.props.match.params.id,
    triggerBatchName: this.props.match.params.name,
    admin_data: cookies.get("admin_data"),
    activeIndex: 0,
    batchConfiguration: [],
    isLoadingConfiguration: false,
  };

  constructor(props) {
    super(props);
    this.handleTabSequenceChange = this.handleTabSequenceChange.bind(this);
    this.loadTriggerBatchConfiguration = this.loadTriggerBatchConfiguration.bind(this);
    this.updateTBConfig = this.updateTBConfig.bind(this);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.handleTargetChangeKeyPress = this.handleTargetChangeKeyPress.bind(this);
  }

  componentDidMount() {
    this.loadTriggerBatchConfiguration();
  }

  updateTBConfig(level, new_amz_config) {
    let batchConfiguration = { ...this.state.batchConfiguration };
    batchConfiguration[level] = JSON.stringify(new_amz_config);
    this.setState({ batchConfiguration });
  }

  async handleTabSequenceChange({ oldIndex, newIndex }) {
    const updateTabs = simpleSwitch(this.getTabs(), oldIndex, newIndex);

    if (
      "high_level" in this.state.batchConfiguration &&
      this.state.batchConfiguration.high_level !== null
    ) {
      const config_high_level = JSON.parse(this.state.batchConfiguration.high_level);
      let new_hl_config = [];

      updateTabs.forEach((tabInfo) => {
        const index = config_high_level.findIndex(function (module) {
          return tabInfo.title === module.module_name;
        });
        new_hl_config.push(config_high_level[index]);
      });

      this.setState({ activeIndex: newIndex });
      this.updateTBConfig("high_level", new_hl_config);
      await reconfigTB(
        this.state.admin_data,
        this.state.triggerBatchId,
        "high_level",
        JSON.stringify(new_hl_config)
      );
    }
  }

  async loadTriggerBatchConfiguration() {
    this.setState({ isLoadingConfiguration: true });
    console.log("WIll load: " + this.state.triggerBatchId);
    const response = await getTBConfiguration(this.state.admin_data, this.state.triggerBatchId);
    if (response.status === "success") {
      let batchConfiguration = {};
      if (response.data !== "") {
        batchConfiguration = response.data;
        console.log("Batch Configuration", batchConfiguration);
        this.setState({ batchConfiguration: batchConfiguration }, () => {
          //this.arrangeTabs();
        });
      }
    }
    this.setState({ isLoadingConfiguration: false });
  }

  getTabs() {
    let tabs = [
      {
        title: "HitList",
        targetDiff: 0,
        enabled: true,
        content: (
          <HitListTriggers
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
      {
        title: `Amazon`,
        targetDiff: 0,
        enabled: true,
        content: (
          <AmazonTriggers
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
      {
        title: `WholeSale`,
        targetDiff: 0,
        enabled: true,
        content: (
          <WholeSaleTriggers
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
      {
        title: `BookStore`,
        targetDiff: 0,
        enabled: true,
        content: (
          <WhaleSaleTrigger
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
      {
        title: `BookScouter`,
        targetDiff: 0,
        enabled: true,
        content: (
          <BookScouterTrigger
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
    ];

    let tabsInOrder = [];

    const highLevelBatchConfiguration =
      "high_level" in this.state.batchConfiguration &&
      this.state.batchConfiguration.high_level !== ""
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    highLevelBatchConfiguration.forEach((tabConfig, _index) => {
      var index = tabs.findIndex(function (tab) {
        return tab.title === tabConfig.module_name;
      });

      if (index >= 0) {
        let thisTab = tabs.splice(index, 1)[0];
        console.log("Pushing " + thisTab.title + " | " + _index);
        thisTab.targetDiff = _index === 0 ? null : tabConfig.target_diff;
        thisTab.enabled = tabConfig.enabled;
        tabsInOrder.push(thisTab);
      }
    });

    tabsInOrder = tabsInOrder.concat(tabs);

    return tabsInOrder;
  }

  onChangeHighLevelTargetDiff(e, title) {
    const targetDiff = e.target.value;

    const highLevelBatchConfiguration =
      "high_level" in this.state.batchConfiguration &&
      this.state.batchConfiguration.high_level !== ""
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === title;
    });
    console.log("Index: " + index);
    if (index >= 0) {
      highLevelBatchConfiguration[index].target_diff = targetDiff;
      this.updateTBConfig("high_level", highLevelBatchConfiguration);
    }
  }

  onChangeHighLevelToggle(e, title) {
    const isChecked = e.target.checked;

    const highLevelBatchConfiguration =
      "high_level" in this.state.batchConfiguration &&
      this.state.batchConfiguration.high_level !== ""
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === title;
    });
    if (index >= 0) {
      highLevelBatchConfiguration[index].enabled = isChecked;
      console.log(highLevelBatchConfiguration);
      let batchConfiguration = { ...this.state.batchConfiguration };
      batchConfiguration.high_level = JSON.stringify(highLevelBatchConfiguration);
      this.setState({ batchConfiguration }, () => {
        this.handleReConfig();
      });
    } else {
      console.log("Not Found: " + index);
      console.log(highLevelBatchConfiguration);
      console.log(highLevelBatchConfiguration);
    }
  }

  async handleReConfig() {
    this.setState({ reconfigInProgress: true });
    await reconfigTB(
      this.state.admin_data,
      this.state.triggerBatchId,
      "high_level",
      this.state.batchConfiguration.high_level
    );
    this.setState({ reconfigInProgress: false });
  }

  handleTargetChangeKeyPress(target) {
    if (target.charCode === 13) {
      this.handleReConfig();
    }
  }

  render() {
    const { activeIndex } = this.state;
    const tabs = this.getTabs();

    //const { tabs, activeIndex } = this.state;
    const tabsTemplate = [];
    const panelTemplate = [];
    tabs.forEach((tab, index) => {
      tabsTemplate.push(
        <DragTab key={index}>
          <div className="tabTitle">
            <div>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  onChange={(e) => this.onChangeHighLevelToggle(e, tab.title)}
                  checked={tab.enabled}
                  type="checkbox"
                  disabled={this.state.reconfigInProgress}
                  className={tab.enabled === false ? " disabledTitle " : "tabTitleLabel"}
                  label={tab.title}
                  id={"checkbox_" + tab.title.toLowerCase()}
                />
              </Form.Group>
            </div>
            <div style={{ marginTop: "30px" }}>
              {"targetDiff" in tab && tab.targetDiff !== null ? (
                <div>
                  <FormControl
                    onChange={(e) => this.onChangeHighLevelTargetDiff(e, tab.title)}
                    value={tab.targetDiff !== null ? tab.targetDiff : ""}
                    autoComplete="off"
                    id="targetDiffInput"
                    placeholder="Difference"
                    type="number"
                    onKeyPress={this.handleTargetChangeKeyPress}
                    disabled={this.state.reconfigInProgress || tab.enabled === false}
                    aria-describedby="basic-addon1"
                    style={{
                      textAlign: "center",
                      borderRadius: 0,
                      border: "none",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "27px",
                    fontWeight: "bold",
                    color: "#28a745",
                  }}
                >
                  Rank #1
                </div>
              )}
            </div>
          </div>
        </DragTab>
      );
      panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
    });

    return (
      <React.Fragment>
        <TopMenu activeKey="/triggers" />

        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div style={{ marginTop: "20px" }}>
                <h3>
                  [[ <span className="text-primary">{this.state.triggerBatchName}</span> ]]
                </h3>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              {this.state.isLoadingConfiguration === true ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Tabs
                  showModalButton={false}
                  customStyle={customStyle}
                  activeIndex={activeIndex}
                  onTabChange={(index) => this.setState({ activeIndex: index })}
                  onTabSequenceChange={this.handleTabSequenceChange}
                >
                  <DragTabList>{tabsTemplate}</DragTabList>
                  <PanelList>{panelTemplate}</PanelList>
                </Tabs>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default TriggerBatch;
