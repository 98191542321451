import { apiBaseUrlLocal } from "./AppConfig";

export const loadShippingPlans = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/shipping_plans/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const editShippingPlan = async (
  admin_data,
  ShippingPlanId,
  ShippingPlanName,
  ShippingPlanData
) => {
  const response = await fetch(apiBaseUrlLocal + "admin/shipping_plans/" + ShippingPlanId, {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      plan_id: ShippingPlanId,
      plan_name: ShippingPlanName,
      plan_json: JSON.stringify(ShippingPlanData),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const deleteShippingPlan = async (admin_data, ShippingPlanId) => {
  const response = await fetch(apiBaseUrlLocal + "admin/shipping_plans/" + ShippingPlanId, {
    method: "DELETE",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      plan_id: ShippingPlanId,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const addShippingPlan = async (admin_data, ShippingPlanName, ShippingPlanData) => {
  const response = await fetch(apiBaseUrlLocal + "admin/shipping_plans/", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      plan_name: ShippingPlanName,
      plan_json: JSON.stringify(ShippingPlanData),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
