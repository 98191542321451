import { apiBaseUrlLocal } from "./AppConfig";

export const loadUsers = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  return await response.json();
};

export const loadTeams = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/teams/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const addUser = async (admin_data, fields) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      perPound: fields.perPound.value !== "" ? fields.perPound.value : 0.3,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      emailAddress: fields.emailAddress.value,
      sbybEmail: fields.sbybEmailAddress.value,
      password: fields.password.value,
      mwsSellerId: fields.mwsSellerId.value,
      mwsAuthToken: fields.mwsAuthToken.value,
      rmsApiToken: fields.rmsApiToken.value,
      triggerBatch: fields.triggerBatch.value,
      isActive: fields.isActive.checked ? 1 : 0,
      isHiddenMode: fields.isHiddenMode.checked ? 1 : 0,
      isSalesRank: fields.isSalesRank.checked ? 1 : 0,
      isInfoBoxes: fields.isInfoBoxes.checked ? 1 : 0,
      teamId: "teamId" in fields ? fields.teamId.value : null,
      shippingRatePlan: fields.shippingPlanId.value,
    }),
  });
  if (response.status === 200 || response.status === 400) {
    const data = await response.json();
    return data;
  } else return false;
};

export const editUser = async (admin_data, fields) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/" + fields.user_id, {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      perPound: fields.perPound.value !== "" ? fields.perPound.value : 0.3,
      userId: fields.user_id,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      emailAddress: fields.emailAddress.value,
      sbybEmail: fields.sbybEmailAddress.value,
      password: fields.password.value,
      mwsSellerId: fields.mwsSellerId.value,
      mwsAuthToken: fields.mwsAuthToken.value,
      rmsApiToken: fields.rmsApiToken.value,
      triggerBatch: fields.triggerBatch.value,
      isActive: fields.isActive.checked ? 1 : 0,
      isHiddenMode: fields.isHiddenMode.checked ? 1 : 0,
      isSalesRank: fields.isSalesRank.checked ? 1 : 0,
      isInfoBoxes: fields.isInfoBoxes.checked ? 1 : 0,
      teamId: "teamId" in fields ? fields.teamId.value : null,
      shippingRatePlan: fields.shippingPlanId.value,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const deleteUser = async (admin_data, userId) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/" + userId, {
    method: "DELETE",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
