import { apiBaseUrlLocal } from "./AppConfig";

export const getSources = async (admin_data) => {
  return new Promise((resolve, reject) => {
    fetch(apiBaseUrlLocal + "admin/stats/sources", {
      method: "POST",
      body: new URLSearchParams({
        user_id: admin_data.admin_id,
        app_token: admin_data.token,
      }),
    })
      .then((response) => response.json())
      .then((sources) => {
        resolve(sources);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const getStats = async (admin_data, start_date, end_date, sources) => {
  const response = await fetch(apiBaseUrlLocal + "admin/stats/history", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      start_date: start_date,
      end_date: end_date,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
    }),
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getStatsCount = async (admin_data, sources) => {
  const response = await fetch(apiBaseUrlLocal + "admin/stats/scans_count", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
    }),
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getStatsRejects = async (admin_data, sources) => {
  const response = await fetch(apiBaseUrlLocal + "admin/stats/scans_rejects", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
    }),
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};
