import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Button, Table, Spinner } from "react-bootstrap";
import ModalAdd from "../components/Sounds/ModalAdd";
import { submitNewAudio, getSounds, deleteSound } from "../includes/HelperSounds";
import Cookies from "universal-cookie";
import PlayAudio from "react-simple-audio-player";
import { apiBaseUrlLocal } from "../includes/AppConfig";

const cookies = new Cookies();

class Sounds extends Component {
  state = {
    showAddModal: false,
    admin_data: cookies.get("admin_data"),
    currentFile: null,
    isAddingNewSound: false,
    audioList: [],
    isLoadingAudioList: false,
    soundApiURL: apiBaseUrlLocal.replace("/v1", "")
  };

  constructor() {
    super();
    this.onFormSubmitAddSound = this.onFormSubmitAddSound.bind(this);
    this.onChangeAudioFile = this.onChangeAudioFile.bind(this);
  }

  componentDidMount() {
    this.getAudio();
  }

  onChangeAudioFile = e => {
    if (e.target.files[0] !== undefined) {
      this.setState({ currentFile: e.target.files[0] });
    }
  };

  async getAudio() {
    this.setState({ isLoadingAudioList: true });
    const audioListResponse = await getSounds(this.state.admin_data);
    if (audioListResponse.status === "success") {
      this.setState({ audioList: audioListResponse.data, isLoadingAudioList: false });
    } else {
      this.setState({ isLoadingAudioList: false });
    }
  }

  async onHandleDeleteAudio(audioRecord, _index) {
    this.setState({ isLoadingAudioList: true });
    const audioDeleteResponse = await deleteSound(audioRecord, this.state.admin_data);
    if (audioDeleteResponse.status === "success") {
      this.setState({ audioList: audioDeleteResponse.data, isLoadingAudioList: false });
    } else {
      this.setState({ isLoadingAudioList: false });
    }
  }

  async onFormSubmitAddSound(evt) {
    console.log("Sending new audio to server for upload");
    evt.preventDefault();

    const soundName = evt.target.elements.soundName.value;
    const audioFile = this.state.currentFile;

    if (audioFile !== null && audioFile !== undefined) {
      this.setState({ isAddingNewSound: true });
      // Call API to add trigger
      const response = await submitNewAudio(soundName, audioFile, this.state.admin_data);
      response !== false && response.status === "success" && this.getAudio();
      this.setState({
        showAddModal: false,
        isAddingNewSound: false
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu activeKey="/sounds" />

        <ModalAdd
          showAddModal={this.state.showAddModal}
          isAddingNewSound={this.state.isAddingNewSound}
          onChangeAudioFile={this.onChangeAudioFile}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddSound={this.onFormSubmitAddSound}
        />

        <Container fluid={true}>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Button
                className="actionButtonLessPadding"
                style={{ marginLeft: "8px" }}
                variant="success"
                onClick={() => this.setState({ showAddModal: true })}
              >
                Add New Sound
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Sound Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.isLoadingAudioList === false ? (
                    this.state.audioList.map((audioRecord, _index) => {
                      return (
                        <tr>
                          <td>{audioRecord.name}</td>
                          <td>
                            <div style={{ float: "left" }}>
                              <PlayAudio width={35} url={this.state.soundApiURL + audioRecord.file_path} />
                            </div>
                            <div style={{ marginLeft: "50px", paddingTop: "3px" }}>
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="danger"
                                onClick={() => this.onHandleDeleteAudio(audioRecord, _index)}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Sounds;
