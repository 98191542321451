import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Tabs, DragTabList, DragTab, PanelList, Panel } from "react-tabtab";
import { simpleSwitch } from "react-tabtab/lib/helpers/move";
import * as customStyle from "react-tabtab/lib/themes/bulma";
import AmzFBATriggers from "../Level2/AmzFBATriggers";
import AmzMFTriggers from "../Level2/AmzMFTriggers";

import { processTabSequenceChange } from "../../../includes/HelperTriggerBatches";

class AmzTriggers extends Component {
  state = {
    activeIndex: 0,
  };

  constructor(props) {
    super(props);
    this.handleTabSequenceChange = this.handleTabSequenceChange.bind(this);
  }

  getTabs() {
    let tabs = [
      {
        title: `FBA`,
        content: (
          <AmzFBATriggers
            triggersTypeId={
              this.props.triggersType === "conf_amazon_books"
                ? 11
                : this.props.triggersType === "conf_amazon_music"
                ? 12
                : this.props.triggersType === "conf_amazon_video"
                ? 13
                : 14
            }
            triggerCatName={this.props.triggersType}
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
      {
        title: `MF`,
        content: (
          <AmzMFTriggers
            triggersTypeId={
              this.props.triggersType === "conf_amazon_books"
                ? 21
                : this.props.triggersType === "conf_amazon_music"
                ? 22
                : this.props.triggersType === "conf_amazon_video"
                ? 23
                : 24
            }
            triggerCatName={this.props.triggersType}
            updateTBConfig={this.props.updateTBConfig}
            batchConfiguration={this.props.batchConfiguration}
            admin_data={this.props.admin_data}
            triggerBatchId={this.props.triggerBatchId}
          />
        ),
      },
    ];

    let tabsInOrder = [];

    const amazonBatchConfiguration =
      this.props.triggersType in this.props.batchConfiguration &&
      this.props.batchConfiguration[this.props.triggersType] !== ""
        ? JSON.parse(this.props.batchConfiguration[this.props.triggersType])
        : [];

    console.log("batchConfiguration", this.props.batchConfiguration);
    console.log("amazonBatchConfiguration", amazonBatchConfiguration);

    amazonBatchConfiguration.forEach((tabName) => {
      var index = tabs.findIndex(function(tab) {
        return tab.title === tabName.module_name;
      });

      if (index >= 0) tabsInOrder.push(tabs.splice(index, 1)[0]);
    });

    tabsInOrder = tabsInOrder.concat(tabs);
    return tabsInOrder;
  }

  async handleTabSequenceChange({ oldIndex, newIndex }) {
    const updateTabs = simpleSwitch(this.getTabs(), oldIndex, newIndex);
    this.setState({ activeIndex: newIndex });

    await processTabSequenceChange(
      updateTabs,
      this.props.triggersType,
      this.props.admin_data,
      this.props.batchConfiguration,
      this.props.triggerBatchId,
      this.props.updateTBConfig
    );
  }

  render() {
    const { activeIndex } = this.state;

    const tabs = this.getTabs();

    const tabsTemplate = [];
    const panelTemplate = [];
    tabs.forEach((tab, index) => {
      tabsTemplate.push(<DragTab key={index}>{tab.title}</DragTab>);
      panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
    });

    return (
      <React.Fragment>
        <Container fluid={true} style={{ padding: "0" }}>
          <Row>
            <Col md={12}>
              <Tabs
                customStyle={customStyle}
                activeIndex={activeIndex}
                onTabChange={(index) => this.setState({ activeIndex: index })}
                onTabSequenceChange={this.handleTabSequenceChange}
              >
                <DragTabList>{tabsTemplate}</DragTabList>
                <PanelList>{panelTemplate}</PanelList>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default AmzTriggers;
